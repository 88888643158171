.sidebar-detail {
  padding: 20px;

  .close-btn-container {
    display: flex;
    justify-content: flex-end;

    .close {
      cursor: pointer;
    }
  }

  .title {
    font-weight: 700;
    font-size: 23px;
    line-height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1.3px solid #ffffff;
  }

  .sidebar-detail-single-feature {
    width: 100%;
    table-layout: fixed;

    col.label {
      width: 30%;
    }
    col.content {
      width: 70%;
    }
  }

  .sidebar-detail-item {
    border-bottom: 1.3px solid rgba(255, 255, 255, 0.1);

    th, td {
      padding: 3px;
    }

    th.label {
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      text-align: left;
      overflow-wrap: break-word;
    }
    td.content {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #FFFFFF;
      overflow-wrap: break-word;
      a {
        color: #FFFFFF;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .sidebar-detail {
      left: 0;
      width: 100%;
    }
  }
}
