html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.header {
  position: absolute;
  z-index: 2;
  background-color: #ffffff;
  height: 60px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: calc(100% - 60px);
  padding-top: 60px;
  display: flex;

  .sidebar {
    background: #F6F6F6;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    width: 350px;
    box-sizing: border-box;
    overflow: scroll;
    transition: all 0.5s;
    z-index: 1;
  }

  .sidebar-detail {
    position: absolute;
    z-index: 10;
    background: #313731;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
    top: 0;
    bottom: 0;
    left: 0;
    width: 372px;
    box-sizing: border-box;
    overflow: scroll;
    color: #FFFFFF;
  }

  .map-container {
    position: relative;
    flex-grow: 1;
    width: auto;
    height: 100%;
    box-sizing: border-box;

    .map {
      width: 100%;
      height: 100%;
    }

    .controlBtn {
      position: absolute;
      right: 10px;
      top: 102px;
      background-color: #ffffff;
      border: 0;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 29px;
      padding: 0;
      width: 29px;
      z-index: 2;
      border-radius: 4px;
      box-shadow: 0 0 0 2px rgba(0,0,0,.1);

      svg {
        color: #606668;
      }
    }
    .controlBtn.select {
      svg {
        color: #058cd5;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .map-container {
    // データリストパネル分、地図の中心がずれないように余白を追加。
    // 高さ（84px）を指定すると追加すると角丸（24px）の背景が見えるようになるので、60pxにしている。
    padding-bottom: 60px;

    .maplibregl-ctrl-bottom-right,
    .maplibregl-ctrl-bottom-left {
      bottom: 24px !important; //アトリビューションが重ならないように調整
    }
  }
}
